import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatTreeFlattener } from '@angular/material/tree';
import { ArrayHelper, FormControlsBaseComponent, OptionSelect } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { AccountListLightTreeComponent } from '../account-list-light-tree/account-list-light-tree.component';
import { AccountListHelper } from '../helpers/account-list-light.helper';
import { AccountListLightTreeNode, FlatNode, TreeListConfig } from './../interfaces/account-list-light.interface';

@UntilDestroy()
@Component({
  selector: 'ltlcc-account-list-tree-autocomplete-wrapper',
  templateUrl: './account-list-tree-autocomplete-wrapper.component.html',
  styleUrls: ['./account-list-tree-autocomplete-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'ltlcc-AccountListTreeAutocompleteWrapper-overlay',
      },
    },
  ],
})
export class AccountListTreeAutocompleteWrapperComponent extends FormControlsBaseComponent implements OnInit {
  autocompleteAccountHintClass = AccountListHelper.autocompleteAccountHintClass;
  private readonly selectValidAccountErrorKey = 'selectValidAccount';
  private _nodes$ = new BehaviorSubject<AccountListLightTreeNode[] | undefined>(undefined);
  readonly nodes$ = this._nodes$.pipe(shareReplay());
  // TODO: Consider creating a shared observable as this is also used in ngOnInit
  readonly selectedNodes$: Observable<AccountListLightTreeNode[] | null> = merge(
    this.autocompleteControl.valueChanges,
    this.nodes$
  ).pipe(
    startWith(this.autocompleteControl.value),
    map(() => {
      if (!this.configList?.enableMultipleSelection || !this.nodes) {
        return null;
      }

      const listIds = this.autocompleteControl.value;

      return (listIds ?? []).map((listId: string) => {
        const node = this.findNode(listId);
        return node?.data;
      });
    })
  );
  readonly customError: OptionSelect[] = [
    {
      value: this.selectValidAccountErrorKey,
      label: this.translate.instant('accountList.selectValidAccount'),
    },
  ];

  filteredNodes: AccountListLightTreeNode[] | undefined;

  private treeFlattener: MatTreeFlattener<AccountListLightTreeNode, FlatNode, FlatNode>;

  @ViewChild(AccountListLightTreeComponent) accountList: AccountListLightTreeComponent;
  @ViewChild(MatInput) autocompleteInput: MatInput;

  @Input() configList: TreeListConfig = {
    enableDynamicHeight: true,
  };
  @Input() searchControl: FormControl = new FormControl(null);
  @Input()
  get nodes(): AccountListLightTreeNode[] | undefined {
    return this._nodes$.getValue();
  }
  set nodes(nodes: AccountListLightTreeNode[] | undefined) {
    this._nodes$.next(nodes);
  }

  @Input()
  set inline(inline: boolean | string) {
    this._inline = coerceBooleanProperty(inline);
  }
  get inline(): boolean {
    return this._inline;
  }
  private _inline = false;

  @Output() selectedNode = new EventEmitter<FlatNode>();

  toggleVisibility = false;

  ngOnInit(): void {
    super.ngOnInit();
    const initDatasource = AccountListHelper.MatTreeFlatDataSourceAccountList();
    this.treeFlattener = initDatasource.treeFlattener;

    merge(this.autocompleteControl.valueChanges, this.nodes$)
      .pipe(untilDestroyed(this), distinctUntilChanged(ArrayHelper.isEqual))
      .subscribe(() => {
        if (!this.configList?.enableMultipleSelection && !this.configList.enableClearButton) {
          const listIds: string[] = this.autocompleteControl.value;
          const nodeSelected = listIds?.length ? this.findNode(listIds[0]) : null;

          if (nodeSelected) {
            this.searchControl.setValue(nodeSelected.data.name, { emitEvent: false });
          }
        }
      });

    this.selectedNode
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((selectedNode) => {
        if (!this.configList?.enableMultipleSelection) {
          // When user selects a option for the list, we want to hide the option menu afterwards
          // and only show it again if the user makes a change in the search control.
          this.toggleVisibility = !!selectedNode?.data;
          this.cd.markForCheck();
        }
      });

    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.toggleVisibility) {
        this.toggleVisibility = false;
        this.cd.markForCheck();
      }
    });
  }

  get autocompleteControl(): FormControl {
    return <FormControl>(this.parentControl?.control ?? this.control);
  }

  get flattenedList(): FlatNode[] {
    if (!Array.isArray(this.nodes)) {
      return [];
    }
    return this.treeFlattener.flattenNodes(this.nodes).slice();
  }

  removeAccount(node: AccountListLightTreeNode): void {
    const listIds = this.autocompleteControl.value?.slice() ?? [];
    const index = listIds.findIndex((listId: string) => listId === node.listId);
    if (index >= 0) {
      listIds.splice(index, 1);
      this.autocompleteControl.setValue(listIds);
    }
    this.selectedNode.emit({ data: node, expandable: false, level: null });
  }

  clear(): void {
    if (!this.configList?.enableMultipleSelection) {
      this.autocompleteControl.setValue(null);
    }
    this.searchControl.setValue(null);
    this.selectedNode.emit(null);
  }

  scrollToStart(): void {
    this.accountList?.viewport?.scrollToIndex(0);
  }

  handleBlurOnSearch(): void {
    // If the user typed something in to the search field, and did not select something from the list of locations,
    // set required account error
    if (!this.autocompleteControl.value && this.required && this.searchControl.value) {
      this.searchControl.setErrors({ [this.selectValidAccountErrorKey]: true });
    }
  }

  private findNode(listId: string): FlatNode | undefined {
    if (!listId || !Array.isArray(this.nodes)) {
      return undefined;
    }
    const list = this.flattenedList;
    return list.find((node) => node.data.listId === listId);
  }
}
