<div class="ltlcc-ProChipList-buttonContainer">
  <mat-form-field [floatLabel]="true" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list [required]="required" #chipList [formGroup]="listArray">
      <mat-chip
        *ngFor="let pro of listArray.controls"
        [removable]="true"
        (removed)="remove(pro)"
        [ngClass]="{ 'ltlcc-ProChipList--invalid': pro.invalid }"
      >
        <mat-icon *ngIf="!pro.valid" class="ltlcc-ProChipList-invalidIcon">error</mat-icon>
        {{ pro.value }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="true"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
      <mat-icon *ngIf="!!listArray.controls.length" (click)="clear()" class="ltlcc-ProChipList-icon"> delete </mat-icon>
    </mat-chip-list>
  </mat-form-field>
  <ng-content></ng-content>
</div>
<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
